<template>
  <div class="content">
    <p>this is content</p>
    <el-input
      type="textarea"
      placeholder="请输入内容"
      v-model="textarea"
      :maxlength="maxlength"
      show-word-limit
      resize="none"
      rows="7"
      class="textarea"
      clearable
    >
    </el-input>
    <el-divider content-position="right"
      ><el-button type="success" round @click="clearnInput">清空输入</el-button>
      <el-button type="primary" round @click="useClaws">开始转换</el-button>
    </el-divider>
  </div>
</template>

<script>
export default {
  data: () => ({
    textarea: "",
    maxlength: 10000,
  }),
  methods: {
    clearnInput() {
      this.textarea = "";
    },
    async useClaws() {
      const body = {
        email: "a.nobody@here.ac.uk",
        tagset: "c7",
        style: "horiz",
        text: this.textarea,
      };
      const { data } = await this.$store.dispatch("utils/useClaws", body);
      console.log(data);
    },
  },
};
</script>

<style scoped>
.content {
  width: 80vw;
  margin: 0 auto;
}
</style>